import React from "react";

import { Location } from "../../interfaces/Location";

import "../../theme/AllUsers.css";
import "../../theme/UsersGrid.css";
import BrandingSettings from "../BrandingSettings";
import { usePortal } from "../PortalProvider";
import "../../theme/locationDetails/taxes/LocationDetailsBranding.css";
import { RicsSideMenuTab } from "../../interfaces/RicsSideMenuTab";
import RicsSideMenuTabHeader from "../RicsSideMenuTabHeader";
export interface LocationDetailsBrandingProps {
  selectedTab: RicsSideMenuTab;
  currentLocation: Location;
}

const LocationDetailsBranding: React.FC<LocationDetailsBrandingProps> = (
  props
) => {
  const getBrandingSettings = (locationId: string) => {
    let brandingSettings = portal?.State.brandingSettings[locationId]
      ? portal.State.brandingSettings[locationId]
      : portal?.State.brandingSettings["Tenant"];

    if (!brandingSettings) {
      brandingSettings = {
        id: "",
        receiptIntroText: getConfigurationIntroText(),
        receiptFooterText: getConfigurationFooterText(),
        logos: {
          smallLogoUrl: portal?.State.currentLocation.imageUrl,
        },
      };
    }

    return brandingSettings;
  };

  const getConfigurationIntroText = () => {
    return getConfigurationText(portal?.configurations.ReceiptIntroText!);
  };

  const getConfigurationFooterText = () => {
    return getConfigurationText(portal?.configurations.ReceiptFooterText!);
  };

  const getConfigurationText = (configurationName: string) => {
    if (
      portal?.configurations.configurations &&
      portal?.configurations.configurations[configurationName]
    ) {
      var allReceiptIntroConfigs =
        portal?.configurations.configurations[configurationName];
      for (let i = 0; i < allReceiptIntroConfigs.length; i++) {
        let currentConfig = allReceiptIntroConfigs[i];
        if (
          currentConfig.LocationId &&
          currentConfig.LocationId.indexOf(
            portal.State.currentLocation.locationId
          ) >= 0 &&
          currentConfig.ConfigurationValue &&
          currentConfig.ConfigurationValue.length > 0
        ) {
          return currentConfig.ConfigurationValue;
        }
      }
    }

    return "";
  };

  let portal = usePortal();
  let brandingSettings = getBrandingSettings(props.currentLocation.locationId);

  return (
    <>
      <RicsSideMenuTabHeader selectedTab={props.selectedTab} />
      <div id="location-details-branding">
        <div className="tabbedSection">
          <div className="tabContent">
            <div className="tabInnerContent" id="Branding">
              <BrandingSettings
                settings={brandingSettings!}
                locationId={props.currentLocation.locationId}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LocationDetailsBranding;
